import { useRef, useState } from "react";
import { useUnmount } from "usehooks-ts";

const LOG = "ExponentialBackOff";
const DEFAULT_MAX_RETRY = 5;

export const useExponentialBackoff = (
  fn: () => unknown,
  maxRetry: number | "infinite" = DEFAULT_MAX_RETRY
) => {
  const retryCountRef = useRef(0);
  const [retryCountReached, setRetryCountReached] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const handleStop = () => {
    console.log(`${LOG}: stop`);

    retryCountRef.current = 0;
    setRetryCountReached(false);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  };

  const run = () => {
    if (timeoutRef.current) {
      console.log(`${LOG}: already retrying count ${retryCountRef.current}`);
      return;
    }

    if (maxRetry !== "infinite" && retryCountRef.current >= maxRetry) {
      console.log(`${LOG}: number of retries (${maxRetry}) reached`);
      return;
    }

    retryCountRef.current += 1;

    // Dont go exponential after x trials, keep it linear
    const delay =
      Math.pow(
        2,
        maxRetry === "infinite" && retryCountRef.current === DEFAULT_MAX_RETRY
          ? DEFAULT_MAX_RETRY
          : retryCountRef.current
      ) * 1000;

    console.log(`${LOG}: retry count ${retryCountRef.current} in ${delay}`);

    if (retryCountRef.current === maxRetry) {
      setRetryCountReached(true);
    }

    timeoutRef.current = setTimeout(() => {
      fn();
      timeoutRef.current = undefined;
    }, delay);
  };

  useUnmount(() => {
    handleStop();
  });

  return {
    run,
    stop: handleStop,
    retryCountReached,
  };
};
