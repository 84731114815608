import { useProfileModal } from "@/pages/profile/utils/ProfileModalContext";
import { saveUpdatedUserProfile } from "@/pages/profile/utils/ProfileScreenUtils";
import { paths } from "@/routerPaths";
import { routerUrl } from "@/utils";
import {
  getLocalDirectorToken,
  getLocalOdienceEvents,
  getLocalOdienceUser,
  getLocalUserFirstName,
  getLocalUserLastName,
  setLocalOdienceEvents,
} from "@/utils/helpers/localstorage";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import {
  getEmail,
  getInvite,
  getInvitedEventId,
  getLastEventId,
  removeLastEventId,
  removeLastGroupId,
} from "@/utils/helpers/sessionStorage";
import { useQuery } from "@tanstack/react-query";
import { useNetworkState } from "@uidotdev/usehooks";
import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { useUnmount } from "usehooks-ts";
import {
  OdienceEvent,
  OdienceUser,
  authenticateDirector,
  fetchEvents,
  fetchPublicEvents,
} from "./useDirectorAuthentication";
import { useExponentialBackoff } from "./useExponentialBackoff";
import { useOdienceOrganization } from "./useOdienceOrganization";

export function useOdienceEvents({
  updateUserAvatar,
}: {
  updateUserAvatar: (avatar: string) => void;
}) {
  const { organizationId, embeddedMode } = useOdienceOrganization();
  const { updateUserDisplayName, updateUserEmail } = useProfileModal();
  const odienceUser = getLocalOdienceUser();
  const [authenticatedUser, setAuthenticatedUser] = useState(
    odienceUser ? JSON.parse(odienceUser) : null
  );
  const displayName = authenticatedUser?.name;
  const firstName = getLocalUserFirstName();
  const lastName = getLocalUserLastName();
  const emailInvite = getEmail();
  const invitedId = getInvite();
  const invitedEventId = getInvitedEventId();

  const filterEvents = (events: OdienceEvent[]) => {
    return events.filter(
      (event) =>
        event.label !== "ended" &&
        (!embeddedMode ||
          (embeddedMode &&
            (!organizationId || organizationId === event.organization_id)))
    );
  };

  const getAuthenticatedUser = async () => {
    return (await authenticateDirector()) as OdienceUser;
  };

  const {
    data: objEvents,
    isPending: eventsPending,
    refetch: refetchEvents,
    isFetching,
  } = useQuery({
    queryKey: ["odienceEvents"],
    queryFn: async () => {
      if (isProvisioned()) {
        const authenticatedUser = await getAuthenticatedUser();
        setAuthenticatedUser(authenticatedUser);
        if (authenticatedUser.avatar) {
          updateUserAvatar(authenticatedUser.avatar);
        }
      }

      const events = isProvisioned()
        ? await fetchEvents()
        : await fetchPublicEvents();

      if (events) {
        setLocalOdienceEvents(events);
      }

      return events;
    },
    initialData: () => getLocalOdienceEvents() || undefined,
    staleTime: 0,
    refetchOnMount: "always",
  });

  const handleUpdateUser = async (updatedEmail: string) => {
    await saveUpdatedUserProfile(
      authenticatedUser,
      firstName,
      lastName,
      displayName,
      updatedEmail,
      emailInvite,
      invitedId,
      invitedEventId || getLastEventId(),
      updateUserAvatar,
      updateUserDisplayName,
      updateUserEmail
    );
  };

  const socketRef = useRef<SocketIOClient.Socket | null>(null);
  const network = useNetworkState();

  const connectSocket = () => {
    if (!network.online || !isProvisioned() || !authenticatedUser) {
      return;
    }

    disconnectSocket();

    const url = routerUrl;
    const userId = authenticatedUser.msisdn;

    socketRef.current = io(url, {
      transports: ["websocket"],
      query: { user_id: userId, token: getLocalDirectorToken() },
    });

    socketRef.current.on("Connected", () => {
      console.log("Connected to socket");
      stopReconnectSocketRetry();
    });

    socketRef.current.on("EventsListUpdateAvailable", refetchEvents);
    socketRef.current.on("EventUpdated", refetchEvents);
    socketRef.current.on("EventRemoved", refetchEvents);

    socketRef.current.on("UserEmailVerified", async (data) => {
      const updatedEmail = data.email;
      await handleUpdateUser(updatedEmail);
    });

    socketRef.current.on("connect_timeout", (error) => {
      console.error("Socket connect timeout: ", error);
      reconnectSocket();
    });

    socketRef.current.on("connect_error", (error) => {
      console.error("Socket connect error:", error);
      reconnectSocket();
    });

    socketRef.current.on("disconnect", (error) => {
      console.error("Socket disconnected: ", error);
      reconnectSocket();
    });

    socketRef.current.on("error", (error) => {
      console.error("Socket disconnected: ", error);
      reconnectSocket();
    });
  };

  const disconnectSocket = () => {
    console.log("Disconnecting from socket");
    socketRef.current?.removeAllListeners();
    socketRef.current?.disconnect();
    socketRef.current = null;
  };

  const { run: reconnectSocket, stop: stopReconnectSocketRetry } =
    useExponentialBackoff(connectSocket, "infinite");

  useEffect(() => {
    void connectSocket();

    return () => {
      disconnectSocket();
      stopReconnectSocketRetry();
    };
  }, [objEvents, network.online]);

  useUnmount(() => {
    disconnectSocket();
    stopReconnectSocketRetry();
  });

  return {
    objEvents: objEvents && filterEvents(objEvents),
    eventsPending,
    embeddedMode,
    isFetching,
  };
}

export const generateEventListPath = (
  objEvent: OdienceEvent,
  embeddedMode: boolean
) => {
  removeLastGroupId();
  removeLastEventId();

  if (isProvisioned()) {
    // This logic is expected for now, hitting back in embedded mode from a private event should redirect to the list of events only from the organization event
    const organizationIdQuery =
      embeddedMode && objEvent && !objEvent.is_public
        ? `?organizationId=${objEvent.organization_id}`
        : "";

    return paths.odience + organizationIdQuery;
  } else {
    return paths.previewOdience;
  }
};
